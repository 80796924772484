import { Profil } from "../enum/profil.enum";
import { TicketStatus } from "../enum/ticket.enum";
import { Conf } from "../hooks/useConfFetch";
import {
  CustomerTicket,
  Entity,
  FicheClientApiData,
  MappedAcount,
  Mission,
  PaymentType,
  TenantUnitsList,
} from "../interfaces/ficheClient.interface";
import { PropertyStakeholder } from "./propertyStakeholder";

export class Tenant extends PropertyStakeholder {
  constructor(conf: Conf) {
    super(conf);
  }

  /**
   * @override
   */
  protected getTicketsCount(unit: TenantUnitsList, customerTicketList: CustomerTicket[], entityId: string): number {
    return customerTicketList.filter(
      (ticket) =>
        ticket.entity_id === entityId &&
        ticket.account_id === unit.tenant_account_id &&
        ticket.status !== TicketStatus.FINISHED
    ).length;
  }

  /**
   * @override
   */
  protected getMissionsCount(entityId: string, missionList: Mission[], unit: TenantUnitsList): number {
    return missionList.filter(
      (mission) => mission.entity_id === entityId && mission.related_account_id === unit.tenant_account_id
    ).length;
  }

  /**
   * @override
   */
  protected getAccountId(unit: TenantUnitsList): string {
    return unit.tenant_account_id;
  }

  /**
   * @override
   */
  protected getCompleteAddress(unit: TenantUnitsList): string {
    return unit.complete_address;
  }

  /**
   * @override
   */
  protected buildAccounts(data: FicheClientApiData, entity: Entity): Map<string, MappedAcount[]> {
    const units: Map<string, MappedAcount[]>[] = [];
    const tenantUnits = this.mapUnits(data, data.tenant_units_list, entity, Profil.TENANT);
    units.push(tenantUnits);
    return this.flattenUnits(units);
  }

  /**
   * @override
   */
  protected addExtraAccountData(entity: Entity, unit: TenantUnitsList): Partial<MappedAcount> {
    const paymentType = entity.payment_type?.map((payment: PaymentType) => payment.paymentType).join(", ");

    return {
      paymentType,
      reminderLevel: unit.recovery_reminder_level,
      lastAGDate: unit.last_general_assembly_date,
      nextAGDate: unit.next_general_assembly_date,
      agencyName: unit.agency_name,
      properties: [
        {
          id: unit.unit_id,
          type: this.translateUnitType(unit.unit_type),
          url: this.getUnitAdbLink(unit),
        },
      ],
    };
  }
}
