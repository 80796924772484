import { Profil } from "../enum/profil.enum";
import { TicketStatus } from "../enum/ticket.enum";
import { Conf } from "../hooks/useConfFetch";
import {
  CustomerTicket,
  Entity,
  FicheClientApiData,
  LessorUnitsList,
  MappedAcount,
  Mission,
} from "../interfaces/ficheClient.interface";
import { PropertyStakeholder } from "./propertyStakeholder";

export class Lessor extends PropertyStakeholder {
  constructor(conf: Conf) {
    super(conf);
  }

  /**
   * @override
   */
  protected getTicketsCount(unit: LessorUnitsList, customerTicketList: CustomerTicket[], entityId: string): number {
    return customerTicketList.filter(
      (ticket) =>
        ticket.entity_id === entityId &&
        ticket.account_id === unit.lessor_account_id &&
        ticket.status !== TicketStatus.FINISHED
    ).length;
  }

  /**
   * @override
   */
  protected getMissionsCount(entityId: string, missionList: Mission[], unit: LessorUnitsList): number {
    return missionList.filter(
      (mission) => mission.entity_id === entityId && mission.related_account_id === unit.lessor_account_id
    ).length;
  }

  /**
   * @override
   */
  protected getAccountId(unit: LessorUnitsList): string {
    return unit.lessor_account_id;
  }

  /**
   * @override
   */
  protected getCompleteAddress(unit: LessorUnitsList): string {
    return `${unit.building_address} ${unit.building_city} ${unit.building_zip_code}`;
  }

  /**
   * @override
   */
  protected buildAccounts(data: FicheClientApiData, entity: Entity): Map<string, MappedAcount[]> {
    const units: Map<string, MappedAcount[]>[] = [];
    const lessorUnits = this.mapUnits(data, data.lessor_units_list, entity, Profil.LESSOR);
    units.push(lessorUnits);
    return this.flattenUnits(units);
  }

  /**
   * @override
   */
  protected addExtraAccountData(entity: Entity, unit: LessorUnitsList): Partial<MappedAcount> {
    return {
      agencyName: unit.agency_name,
    };
  }
}
